import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const Section = styled.section`
  background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/dobra06-athletico-bg/image.webp');
  background-repeat: no-repeat;
  background-size: cover;

  padding: 40px 0;
  display: flex;
  flex-direction: column;
  gap: 24px;


  @media ${device.tablet} {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/dobra06-athletico-bg/image.webp');
    flex-direction: row;
    padding: 115px 0;

    >div{
      flex-basis: 50%;
    }
  }

  @media ${device.desktopLG} {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/dobra06-athletico-bg/image.webp');
    padding: 65px 0;
  }

  @media ${device.desktopXL} {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/dobra06-athletico-bg/image.webp');
    padding: 95px 0;
    gap: 100px;
  }

  button, a {
    height: 48px;
  }
`

export const ContentInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  gap: 24px;

  .title{
    font-family: 'Citrina', Helvetica, sans-serif;
    font-size: 28px;
    font-weight: 500;
    line-height: 33.6px;
    margin: 0;
    color: #ffffff;
  }
  .description{
    font-family: 'Inter', Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    margin: 0;
    color: #ffffff;
  }
  .box-cta{}

  @media ${device.tablet} {
    padding-left: 35px;
    gap: 12px;
    justify-content: center;

    .title{
      font-size: 40px;
      line-height: 44px;
    }
    .description{
      font-size: 16px;
      line-height: 19.36px;
      margin-bottom: 12px;
    }
  }

  @media ${device.desktopXL} {
    padding-left: 150px;

    .title{
      font-size: 48px;
      line-height: 52.8px;
    }
    .description{
      font-size: 18px;
      line-height: 21.78px;
    }
  }

`
export const ContentImage = styled.div`
  padding-left: 24px;
  display: flex;  
  width: 100%;
  justify-content: flex-end;
  align-items: center;

  img{
    width: 100%;
  }

  @media ${device.tablet} {
    padding-left: 0;
  }
`
