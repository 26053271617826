import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const Section = styled.section`
  background-color: #161616;
  padding: 40px 24px;
  display: flex; 
  flex-direction: column;
  gap: 24px;


  button, a {
    height: 48px;
    max-width: 100%
  }

  @media ${device.tablet} {
    padding: 130px 35px;
    flex-direction: row-reverse;

    >div{
      flex-basis: 50%;
    }
  }

  @media ${device.desktopXL} {
    padding: 121px 150px;
    gap: 100px;
  }
`

export const ContentImage = styled.div`
  img {
    width: 100%;
  }

  @media ${device.tablet} {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const ContentInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 38px;

  .title {
    font-family: 'Citrina', Helvetica, sans-serif;
    font-size: 28px;
    font-weight: 500;
    line-height: 33.6px;
    margin: 0;
    color: #ffffff;
  }

  .description {
    font-family: 'Inter', Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    margin: 0;
    color: #ffffff;
  }

  @media ${device.tablet} {
    gap: 38px;

    .title{
      font-size: 40px;
      line-height: 44px;
    }
    .description{
      font-size: 16px;
      line-height: 19.36px;
    }
  }

  @media ${device.desktopLG} {
    justify-content: center;

    .title{
      font-size: 48px;
      line-height: 52.8px;
    }
    .description{
      font-size: 18px;
      line-height: 21.78px;
    }
  }
`
