import { white } from 'src/styles/colors'
import styled from 'styled-components'

import BackgroundLG from '../../assets/image/background-hero-athletico-paranaense-lg.jpg'
import BackgroundMD from '../../assets/image/background-hero-athletico-paranaense-md.jpg'
import BackgroundMobile from '../../assets/image/background-hero-athletico-paranaense-mobile.jpg'
import BackgroundXL from '../../assets/image/background-hero-athletico-paranaense-xl.jpg'

import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  background-image: url(${BackgroundMobile});
  background-repeat: no-repeat;
  background-size: cover;

  padding: 40px 24px;

  @media ${device.tablet} {
    background-image: url(${BackgroundMD});
    padding: 150px 35px 60px 35px;
  }

  @media ${device.desktopLG} {
    background-image: url(${BackgroundLG});
  }

  @media ${device.desktopXL} {
    background-image: url(${BackgroundXL});
    padding: 130px 155px 90px 155px;
  }

  button, a {
    height: 48px;
    max-width: 100%
  }
`

export const Card = styled.div`
  background-color: ${white};
  border-radius: 8px;
  @media ${device.tablet} {
    width: 232px;
    height: 156px;
  }
`

export const AthleticoBenefits = styled.div`
.benefits {
  margin-bottom: 16px;
}
.benefits:last-child {
  margin-bottom: 0;
 }
`

export const Cards = styled.div`
.card {
  margin-right: 0;
  margin-bottom: 16px;
  @media ${device.desktopXL} {
    margin-bottom: 0;
    margin-top: 0;
  }
}

.card:last-child {
  margin-bottom: 0;
  @media ${device.desktopXL} {
    grid-column: 2;
  }
 }
`

export const ContentImage = styled.div`
  img{
    width: 100%;
  }

  @media ${device.tablet} {
    display: flex;
    align-items: center;
    justify-content: center;

    img{
      width: auto;
      height: 420px;
    } 
  }

  @media ${device.desktopXL} {
    img{
      height: 500px;
    } 
  }

  @media ${device.desktopXL} {
    img{
      height: 580px;
    } 
  }
`

export const ContentText = styled.div`
  display: flex; 
  flex-direction: column;
  gap: 12px;

  .title{
    font-family: 'Citrina', Helvetica, sans-serif;
    font-size: 40px;
    font-weight: 500;
    line-height: 44px;
    color: #ffffff;
    margin: 0;
  }
  .subtitle{
    font-family: 'Citrina', Helvetica, sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    color: #ffffff;
    margin: 0;
  }
  .description{
    font-family: 'Inter', Helvetica, sans-serif;
    font-size: 13.9px;
    font-weight: 400;
    line-height: 16.94px;
    color: #ffffff;
    margin: 0;
  }

  @media ${device.tablet} {
    padding-top: 50px;

    .title{
      font-size: 40px;
      line-height: 44px;
    }
    .subtitle{
      font-size: 24px;
      line-height: 28.8px;
    }
    .description{
      font-size: 16px;
      line-height: 19.36px;
    }
  }

  @media ${device.desktopLG} {
    .title{
      font-size: 56px;
      line-height: 61.6px;
    }
    .subtitle{
      font-size: 28px;
      line-height: 33.6px;
    }
    .description{
      font-size: 18px;
      line-height: 21.78px;
    }
  }

  @media ${device.desktopXL} {
    .title{
      font-size: 80px;
      line-height: 88px;
    }
    .subtitle{
      font-size: 32px;
      line-height: 38.73px;
    }
  }
`

export const BoxLogo = styled.div`
  width: 100%;
  text-align: center;
`

export const BoxTop = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media ${device.tablet} {
    flex-direction: row;
    align-items: flex-start;
    >div{
      flex-basis: 50%;
    }
  }

  @media ${device.desktopLG} {
    align-items: center;
  }
`

export const BoxMid = styled.div`
  padding: 30px 0;
  text-align: center;
  width: 100%;

  img{
    height: 60px;
  }

  @media ${device.tablet} {
    padding: 90px 0;
    img{
      height: 65px;
    }
  }
`

export const BoxBot = styled.div`
  display: flex; 
  gap: 24px;
  flex-direction: column;

  @media ${device.tablet} {
    flex-direction: row;
    gap: 85px;

    >div{
      flex-basis: 50%;
    }
  }

  @media ${device.desktopLG} {
    gap: 45px;
  }

  @media ${device.desktopXL} {
    gap: 100px;
  }

`

export const BoxCTA = styled.div`
  display: flex;
  flex-direction: column;
  gap: 38px;

  .title{
    font-family: 'Citrina', Helvetica, sans-serif;
    font-size: 28px;
    font-weight: 500;
    line-height: 33.6px;
    color: #fff;
    margin: 0;
  }
  .description{
    font-family: 'Inter', Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    color: #fff;
    margin: 0;
  }
  .cta{

  }

  @media ${device.tablet} {
    .title{
      font-size: 40px;
      line-height: 44px;
    }
    .description{
      font-size: 18px;
      line-height: 21.78px;
    }
  }

  @media ${device.desktopXL} {
    .title{
      font-size: 48px;
      line-height: 52.8px;
    }
  }

`

export const BoxCards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  .boxItem{
    display: flex;
    flex-direction: row;
    gap: 24px;
  }

  .boxIcon svg{
    width: 24px;
    height: 24px;
  }

  .boxText{
    display: flex;
    align-items: center;

    p{
      font-family: 'Inter', Helvetica, sans-serif;
      font-size: 14px;
      font-weight: 600;
      line-height: 16.94px;
      color: #fff;
      margin: 0
    }
  }

  @media ${device.tablet} {
    gap: 16px;

    .boxIcon svg{
      width: 32px;
      height: 32px;
    }

    .boxText{
      p{
        font-size: 16px;
        line-height: 19.36px;
      }
    }
  }

  @media ${device.desktopLG} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px 32px;

    .boxItem{
      align-items: center;
    }

    &.boxItem:nth-child(odd) {
      grid-column: 1;
    }
    &.boxItem:nth-last-child(even) {
      grid-column: 1;
    }
  }

  @media ${device.desktopXL} {
    height: 220px;
  }

`
